import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "reg-copy" }
const _hoisted_3 = {
  class: "error error-message",
  "data-e2e": "register-form-error"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "disclaimer"
}
const _hoisted_7 = { class: "splide-pagination" }
const _hoisted_8 = { class: "hidden" }
const _hoisted_9 = { class: "splide-bullets" }
const _hoisted_10 = { class: "hidden" }

import { ref, computed } from 'vue';
    import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import EarnDrops from '@components/EarnDrops.vue';
    import * as Form from './';

    
export default {
  __name: 'RegisterStep',
  props: /*@__PURE__*/_mergeModels({
        validators: {
            type: Object,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        step: {
            type: Number,
            default: 0,
        },
        total: {
            type: Number,
            default: 0,
        },
        current: {
            type: Number,
            default: 0,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            required: false,
            default: '',
        },
        errors: {
            type: Array,
            required: false,
            default: () => ([]),
        },
    }, {
    "modelValue": { type: Object },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['onNext', 'onPrev', 'login'], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {

    const store = useStore();
    const app = computed(() => store.state.app);
    const ui = computed(() => store.state.ui);

    const emit = __emit;
    

    const { t, vT } = useI18next(['profile', 'registration', 'buttons', 'links']);

    const form = ref(null);

    const filteredFields = computed(() => __props.fields.filter((field) => {
        if (field.featureFlag) {
            return store.getters['ui/checkFeatureFlag'](field.featureFlag);
        }
        else if (field.uiFeature) {
            return store.state.ui.uiFeatures[field.uiFeature] === 'active';
        }
        return true;
    }));

    const handleStep = async () => {
        emit('onNext');
    };

    const handlePrev = async () => {
        emit('onPrev');
    };

    const fieldsValid = ref({});

    const fieldHasError = (name) => __props.errors.find((err) => err.name === name);

    const formValid = computed(() => __props.fields.filter((field) => fieldsValid.value[field.name]).length === filteredFields.value.length);
    const formInvalid = computed(() => __props.fields.filter((field) => fieldsValid.value[field.name] === false).length > 0);
    const handleValidation = ({ name, isValid }) => {
        fieldsValid.value[name] = isValid;
    };

    // TODO: MOVE TO COPYDECK NODES
    const termsLink = computed(() => `<a class="bold" href="${
        app.value.tb2URL
    }public/fulfillment/terms.pdf" title="${t(
        'links:terms_condition_copy',
    )} ${t('links:pdf')}" target="_blank">${t(
        'links:terms_condition_copy',
    )}</a>`);

    const rulesLink = computed(() => `<a class="bold" href="${
        app.value.tb2URL
    }public/fulfillment/rules.pdf" title="${t(
        'links:rules_copy',
    )} ${t('links:pdf')}" target="_blank">${t(
        'links:rules_copy',
    )}</a>`);
    const financialLink = computed(() => `<a class="bold" href="${
        app.value.tb2URL
    }public/fulfillment/terms.pdf#page=8" title="${t(
        'links:financial_copy',
    )} ${t('links:pdf')}" target="_blank">${t(
        'links:financial_copy',
    )}</a>`);

    const model = _useModel(__props, "modelValue");

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("form", _mergeProps({
      id: `register_form-${__props.step}`
    }, _ctx.$attrs, {
      ref_key: "form",
      ref: form,
      name: "register_form",
      autocomplete: "",
      novalidate: "",
      onSubmit: _withModifiers(handleStep, ["prevent"])
    }), [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("h2", null, null, 512), [
          [_unref(vT), { key: `registration:${__props.step}.headline_copy`, userName: model.value.first_name }]
        ]),
        _withDirectives(_createElementVNode("p", null, null, 512), [
          [_unref(vT), `registration:${__props.step}.body_copy`]
        ])
      ]),
      _withDirectives(_createElementVNode("p", _hoisted_3, null, 512), [
        [_vShow, formInvalid.value || __props.error !== ''],
        [_unref(vT), [`registration:${__props.error}`, 'error_alert']]
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredFields.value, (field) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(Form[field.type]), {
          key: `field-${field.name}`,
          modelValue: model.value[field.name],
          "onUpdate:modelValue": $event => ((model.value[field.name]) = $event),
          "has-error": fieldHasError(field.name),
          error: _unref(t)(`ada.errors.${field.name}`),
          label: _unref(t)(`field.${field.name}`, { termsLink: termsLink.value, financialLink: financialLink.value, rulesLink: rulesLink.value, interpolation: { escapeValue: false } }),
          name: field.name,
          requirements: field.requirements,
          options: field.options,
          disabled: ui.value[field.locked],
          validator: __props.validators[field.name],
          onValidation: handleValidation
        }, null, 40, ["modelValue", "onUpdate:modelValue", "has-error", "error", "label", "name", "requirements", "options", "disabled", "validator"]))
      }), 128)),
      (__props.step === 3)
        ? (_openBlock(), _createBlock(EarnDrops, {
            key: 0,
            points: '100',
            "show-text": true
          }))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        class: _normalizeClass(["btn btn_register", { disabled: !formValid.value, loading: __props.loading }]),
        type: "submit",
        disabled: !formValid.value || __props.loading,
        "data-e2e": "register-form-submit"
      }, _toDisplayString(_unref(t)(`registration:${__props.step}.cta`)), 11, _hoisted_4),
      (__props.step === 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
            _createTextVNode(_toDisplayString(_unref(t)('registration:already_registered')) + " ", 1),
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[0] || (_cache[0] = _withModifiers($event => (emit('login')), ["prevent"]))
            }, _toDisplayString(_unref(t)('registration:already_registered_cta')), 1)
          ]))
        : _createCommentVNode("", true),
      (__props.step === 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_unref(t)('registration:email_account')), 1))
        : _createCommentVNode("", true)
    ], 16, _hoisted_1),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("button", {
        class: _normalizeClass({ disabled: __props.current == 0 }),
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (handlePrev()), ["prevent"]))
      }, [
        _createElementVNode("i", {
          class: _normalizeClass([{ hidden: __props.current == 0 }, "far fa-arrow-left"])
        }, null, 2),
        _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(t)('buttons:previous')), 1)
      ], 2),
      _createElementVNode("ul", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.total, (s, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: s,
            class: _normalizeClass(["splide-bullet", { active: index == __props.step }])
          }, null, 2))
        }), 128))
      ]),
      _createElementVNode("button", {
        class: _normalizeClass({ disabled: !formValid.value }),
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => (handleStep()), ["prevent"]))
      }, [
        _cache[3] || (_cache[3] = _createElementVNode("i", { class: "far fa-arrow-right" }, null, -1)),
        _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(t)('buttons:next')), 1)
      ], 2)
    ])
  ], 64))
}
}

}
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "78",
  height: "77",
  viewBox: "0 0 78 77",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g filter=\"url(#filter0_d_2067_29873)\"><path d=\"M58.0452 57.5452C68.5636 47.0268 68.5636 29.9731 58.0452 19.4547C47.5268 8.93627 30.4731 8.93627 19.9547 19.4547C9.43628 29.9731 9.43628 47.0268 19.9547 57.5452C30.4731 68.0636 47.5268 68.0636 58.0452 57.5452Z\" fill=\"#0066B3\"></path></g><path d=\"M30.409 29.4819L30.0125 29.8784C29.7156 30.1754 29.7156 30.6568 30.0125 30.9537L46.492 47.4332C46.7889 47.7301 47.2703 47.7301 47.5672 47.4332L47.9637 47.0367C48.2607 46.7397 48.2607 46.2583 47.9637 45.9614L31.4843 29.4819C31.1874 29.185 30.7059 29.185 30.409 29.4819Z\" fill=\"white\"></path><path d=\"M47.963 29.879L47.5664 29.4825C47.2695 29.1856 46.7881 29.1856 46.4912 29.4825L30.0117 45.962C29.7148 46.2589 29.7148 46.7403 30.0117 47.0373L30.4082 47.4338C30.7052 47.7307 31.1866 47.7307 31.4835 47.4338L47.963 30.9543C48.2599 30.6574 48.2599 30.176 47.963 29.879Z\" fill=\"white\"></path><defs><filter id=\"filter0_d_2067_29873\" x=\"8.06592\" y=\"11.5659\" width=\"61.868\" height=\"61.8682\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"></feFlood><feColorMatrix in=\"SourceAlpha\" type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"></feColorMatrix><feOffset dy=\"4\"></feOffset><feGaussianBlur stdDeviation=\"2\"></feGaussianBlur><feComposite in2=\"hardAlpha\" operator=\"out\"></feComposite><feColorMatrix type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0\"></feColorMatrix><feBlend mode=\"normal\" in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_2067_29873\"></feBlend><feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect1_dropShadow_2067_29873\" result=\"shape\"></feBlend></filter></defs>", 4)
  ])))
}
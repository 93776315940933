
    import { mapActions } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import BrandsSelector from '@components/BrandsSelector.vue';
    // import loyalty from '../loyalty';

    import BaseModal from './BaseModal';

    export default {
        name: 'BrandSelectionModal',

        components: {
            BrandsSelector,
        },

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['brand_selection', 'buttons']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'brand-selection',
                brand: '',
            };
        },

        methods: {
            ...mapActions({
                updateCurrentProfile: 'profile/updateCurrentProfile',
            }),
            onSelection (brand) {
                this.brand = brand;
            },
            async submitBrand () {
                if (this.brand === '') {
                    return;
                }

                await this.updateCurrentProfile({ 'preferred_brand': this.brand });

                /* Since preferred brand is no longer set on enrollment, the LP preferred brand
                   enrollment email is no longer triggering. This is being setup to take care
                   of that in a new way. The this.updateCurrentProfile() event must complete first with
                   the actual preferred brand in order for this to work properly. */
                // await loyalty.recordEvent('set_pref_brand');

                this.closeModal();
            },
        },
    };

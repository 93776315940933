export const routes = [
    { oldRoute: '#/arrowhead', newRoute: '/arrowhead' },
    { oldRoute: '#/polandspring', newRoute: '/polandspring' },
    { oldRoute: '#/purelife', newRoute: '/purelife' },
    { oldRoute: '#/deerpark', newRoute: '/deerpark' },
    { oldRoute: '#/icemountain', newRoute: '/icemountain' },
    { oldRoute: '#/splash', newRoute: '/splash' },
    { oldRoute: '#/ozarka', newRoute: '/ozarka' },
    { oldRoute: '#/zephyrhills', newRoute: '/zephyrhills' },
    { oldRoute: '#/landing', newRoute: '/landing' },
    { oldRoute: '#/register', newRoute: '/landing' },
    { oldRoute: '#/login', newRoute: '/landing' },
];

export const affiliateID = [
    { affiliate: 'zhnrdahbpnovsweeps', route: '/zephyrhills' },
    { affiliate: 'spnrdahbpnovsweeps', route: '/splash' },
    { affiliate: 'plnrdahbpnovsweeps', route: '/purelife' },
    { affiliate: 'oznrdahbpnovsweeps', route: '/ozarka' },
    { affiliate: 'imnrdahbpnovsweeps', route: '/icemountain' },
    { affiliate: 'ahnrdahbpnovsweeps', route: '/arrowhead' },
    { affiliate: 'dpnrdahbpnovsweeps', route: '/deerpark' },
    { affiliate: 'psnrdahbpnovsweeps', route: '/polandspring' },
];

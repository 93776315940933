import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-overlay" }
const _hoisted_2 = {
  class: "modal brand-selection",
  role: "dialog"
}
const _hoisted_3 = { class: "brand-copy" }
const _hoisted_4 = { class: "button" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BrandsSelector = _resolveComponent("BrandsSelector")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString($setup.t('brand_selection:headline_copy')), 1),
        _createElementVNode("p", null, _toDisplayString($setup.t('brand_selection:body_copy')), 1)
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.submitBrand && $options.submitBrand(...args)), ["prevent"]))
      }, [
        _createVNode(_component_BrandsSelector, { onSelection: $options.onSelection }, null, 8, ["onSelection"]),
        _createElementVNode("button", _hoisted_4, _toDisplayString($setup.t('buttons:finish')), 1)
      ], 32)
    ])
  ]))
}
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createVNode as _createVNode, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "bar-container" }
const _hoisted_2 = { class: "bar" }
const _hoisted_3 = {
  key: 1,
  class: "home-link",
  "data-e2e": "footer-logged-in"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 0,
  class: "hamburger-menu"
}
const _hoisted_6 = { class: "sidebar" }
const _hoisted_7 = { class: "top-menu" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 3 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "small-only" }
const _hoisted_16 = { class: "large-only headline-copy-med has-color-white" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "small-only" }
const _hoisted_20 = { class: "large-only headline-copy-med has-color-white" }
const _hoisted_21 = { class: "merch_chance_to_win" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { class: "small-only" }
const _hoisted_25 = { class: "large-only headline-copy-med has-color-white" }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { class: "footer" }
const _hoisted_28 = { class: "animation" }
const _hoisted_29 = { for: "animations" }
const _hoisted_30 = { class: "disclaimer" }
const _hoisted_31 = ["href", "title"]
const _hoisted_32 = ["innerHTML"]

import { ref, computed } from 'vue';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import HamburgerButton from '@components/HamburgerButton.vue';
    import RewardsDropLogo from '@components/svgs/RewardsDropLogo.vue';

    
export default {
  __name: 'HeaderNavigation',
  setup(__props) {

    const hideInPhase = ['pre-launch'];

    const route = useRoute();
    const store = useStore();
    const ui = computed(() => store.state.ui);
    const { vT, t } = useI18next(['navigation']);
    const isOpen = ref(false);
    const inBar = ref(false);
    const app = computed(() => store.state.app);

    const loggedIn = computed(() => store.getters['profile/loggedIn']);
    const logOut = () => store.dispatch('profile/logOut');
    const termsCondition = computed(() => `<a href="${app.value.tb2URL}public/fulfillment/terms.pdf" target="_blank">${t('links:terms_condition_copy')}</a>`);

    const dropdowns = ref({
        earn: false,
        redeem: false,
        howItWorks: false,
    });

    const animation = computed({
        get () {
            return ui.value.reduceAnimation;
        },
        set (newValue) {
            store.commit('ui/updateAnimation');
        },
    });

    const toggleDropdown = (section) => {
        for (const key in dropdowns.value) {
            if (key === section) {
                dropdowns.value[key] = !dropdowns.value[key];
            }
            else {
                dropdowns.value[key] = false;
            }
        }
    };

    const updateOpen = () => {
        isOpen.value = !isOpen.value;
    };

    const openSigninModal = () => {
        if (ui.value.isSticky) {
            store.commit('ui/updateSticky');
        }
    };

    document.addEventListener('scroll', (event) => {
        inBar.value = window.scrollY > 25;
    });

    store.dispatch('promotions/getActivePromotions');
    store.dispatch('catalog/getRewardItems');
    const promotionsExist = computed(() => store.state.promotions.activePromotions.length > 0);
    const nonWhiteLogo = ['/splash', '/landing'];

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["header-navigation", { 'unset-white-fill': nonWhiteLogo.includes(_unref(route).path) }])
  }, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        (loggedIn.value && !isOpen.value)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              class: "home-link",
              to: "/home"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("i", { class: "fal fa-home-lg-alt" }, null, -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (loggedIn.value && isOpen.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
              _createElementVNode("a", {
                href: "/",
                class: "btn_logout",
                "data-e2e": "footer-logout",
                onClick: logOut
              }, _toDisplayString(_unref(t)('navigation:sign_out')), 1)
            ]))
          : _createCommentVNode("", true),
        (!loggedIn.value && isOpen.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
              _createElementVNode("a", {
                href: "/",
                class: "sign-in-link",
                "data-e2e": "footer-logged-in",
                onClick: _cache[0] || (_cache[0] = _withModifiers($event => {updateOpen(); openSigninModal();}, ["prevent"]))
              }, _toDisplayString(_unref(t)('navigation:sign_in')), 1)
            ]))
          : _createCommentVNode("", true),
        (!hideInPhase.includes(app.value.phase))
          ? (_openBlock(), _createBlock(HamburgerButton, {
              key: 3,
              open: isOpen.value,
              "in-bar": inBar.value,
              onClick: _withModifiers(updateOpen, ["prevent"])
            }, null, 8, ["open", "in-bar"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_router_link, { to: "/home" }, {
        default: _withCtx(() => [
          _createVNode(RewardsDropLogo, {
            id: "header-logo",
            class: _normalizeClass({ 'in-bar': inBar.value || !ui.value.isSticky || ui.value.pageName != 'landing' })
          }, null, 8, ["class"])
        ]),
        _: 1
      })
    ]),
    _createVNode(_Transition, { name: "menu" }, {
      default: _withCtx(() => [
        (isOpen.value)
          ? (_openBlock(), _createElementBlock("section", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("ul", _hoisted_7, [
                  (!loggedIn.value)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                        _withDirectives(_createVNode(_component_router_link, {
                          to: "/how_it_works",
                          onClick: _withModifiers(updateOpen, ["prevent"])
                        }, null, 512), [
                          [_unref(vT), 'how_it_works']
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (!loggedIn.value)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                        _withDirectives(_createVNode(_component_router_link, {
                          to: "/faq",
                          onClick: _withModifiers(updateOpen, ["prevent"])
                        }, null, 512), [
                          [_unref(vT), 'faq']
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (loggedIn.value)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                        _withDirectives(_createVNode(_component_router_link, {
                          to: "/home",
                          onClick: _withModifiers(updateOpen, ["prevent"])
                        }, null, 512), [
                          [_unref(vT), 'home']
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("li", null, [
                    _withDirectives(_createVNode(_component_router_link, {
                      to: "/contact",
                      onClick: _withModifiers(updateOpen, ["prevent"])
                    }, null, 512), [
                      [_unref(vT), 'contact']
                    ])
                  ]),
                  (loggedIn.value)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                        _withDirectives(_createVNode(_component_router_link, {
                          to: "/profile",
                          onClick: updateOpen
                        }, null, 512), [
                          [_unref(vT), 'profile']
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (loggedIn.value)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_12, [
                      _createElementVNode("li", null, [
                        _createElementVNode("div", {
                          class: _normalizeClass({ 'toggle-header': true, active: dropdowns.value.earn }),
                          onClick: _cache[1] || (_cache[1] = $event => (toggleDropdown('earn')))
                        }, [
                          (dropdowns.value.earn)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_13, "-"))
                            : (_openBlock(), _createElementBlock("span", _hoisted_14, "+")),
                          _createElementVNode("i", _hoisted_15, _toDisplayString(_unref(t)('navigation:earn')), 1),
                          _withDirectives(_createElementVNode("p", _hoisted_16, null, 512), [
                            [_unref(vT), 'navigation:earn']
                          ])
                        ], 2),
                        _createElementVNode("ul", {
                          class: _normalizeClass({ 'toggle-content': true, hidden: !dropdowns.value.earn })
                        }, [
                          _createElementVNode("li", null, [
                            _withDirectives(_createVNode(_component_router_link, {
                              to: { path: '/earn' },
                              onClick: _withModifiers(updateOpen, ["prevent"])
                            }, null, 512), [
                              [_unref(vT), 'upload']
                            ])
                          ])
                        ], 2)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("div", {
                          class: _normalizeClass({ 'toggle-header': true, active: dropdowns.value.redeem }),
                          onClick: _cache[2] || (_cache[2] = $event => (toggleDropdown('redeem')))
                        }, [
                          (dropdowns.value.redeem)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_17, "-"))
                            : (_openBlock(), _createElementBlock("span", _hoisted_18, "+")),
                          _createElementVNode("i", _hoisted_19, _toDisplayString(_unref(t)('navigation:redeem')), 1),
                          _withDirectives(_createElementVNode("p", _hoisted_20, null, 512), [
                            [_unref(vT), 'navigation:redeem']
                          ])
                        ], 2),
                        _createElementVNode("ul", {
                          class: _normalizeClass({ 'toggle-content': true, hidden: !dropdowns.value.redeem })
                        }, [
                          _createElementVNode("li", _hoisted_21, [
                            _withDirectives(_createVNode(_component_router_link, {
                              to: "/redeem",
                              onClick: _withModifiers(updateOpen, ["prevent"])
                            }, null, 512), [
                              [_unref(vT), 'merch_chance_to_win']
                            ])
                          ])
                        ], 2)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("div", {
                          class: _normalizeClass({ 'toggle-header': true, active: dropdowns.value.howItWorks }),
                          onClick: _cache[3] || (_cache[3] = $event => (toggleDropdown('howItWorks')))
                        }, [
                          (dropdowns.value.howItWorks)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_22, "-"))
                            : (_openBlock(), _createElementBlock("span", _hoisted_23, "+")),
                          _createElementVNode("i", _hoisted_24, _toDisplayString(_unref(t)('navigation:how_it_works')), 1),
                          _withDirectives(_createElementVNode("p", _hoisted_25, null, 512), [
                            [_unref(vT), 'navigation:how_it_works']
                          ])
                        ], 2),
                        _createElementVNode("ul", {
                          class: _normalizeClass({ 'toggle-content': true, hidden: !dropdowns.value.howItWorks })
                        }, [
                          _createElementVNode("li", null, [
                            _withDirectives(_createVNode(_component_router_link, {
                              to: "/how_it_works",
                              onClick: _withModifiers(updateOpen, ["prevent"])
                            }, null, 512), [
                              [_unref(vT), 'overview']
                            ])
                          ]),
                          _createElementVNode("li", null, [
                            _withDirectives(_createVNode(_component_router_link, {
                              to: "/faq",
                              onClick: _withModifiers(updateOpen, ["prevent"])
                            }, null, 512), [
                              [_unref(vT), 'faq']
                            ])
                          ])
                        ], 2)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (loggedIn.value && promotionsExist.value)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_26, [
                      _createElementVNode("li", null, [
                        (loggedIn.value)
                          ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                              key: 0,
                              to: "/promotions",
                              onClick: _withModifiers(updateOpen, ["prevent"])
                            }, null, 512)), [
                              [_unref(vT), 'promotions']
                            ])
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("form", _hoisted_28, [
                    _withDirectives(_createElementVNode("input", {
                      id: "animations",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((animation).value = $event)),
                      type: "checkbox",
                      name: "animations"
                    }, null, 512), [
                      [_vModelCheckbox, animation.value]
                    ]),
                    _withDirectives(_createElementVNode("label", _hoisted_29, null, 512), [
                      [_unref(vT), 'off_animation']
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", null, [
                        _withDirectives(_createElementVNode("p", null, null, 512), [
                          [_unref(vT), 'links:privacy']
                        ])
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("p", null, [
                          _withDirectives(_createElementVNode("a", {
                            href: app.value.tb2URL + 'public/fulfillment/terms.pdf',
                            target: "_blank",
                            title: `${_ctx.$t('links:terms_condition_copy')} ${_ctx.$t('links:new_window')}`
                          }, null, 8, _hoisted_31), [
                            [_unref(vT), 'links:terms_condition_copy']
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("p", {
                      innerHTML: _unref(t)('disclaimer', { termsCondition: termsCondition.value, interpolation: { escapeValue: false } })
                    }, null, 8, _hoisted_32)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}
}

}
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"
import _imports_0 from '@public/img/close.png'


const _hoisted_1 = {
  class: "modal page-forgot-password",
  role: "dialog"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "page-forgot-password-confirm" }
const _hoisted_7 = { class: "sub-heading" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EmailForm = _resolveComponent("EmailForm")
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay profile-modal",
    onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!$data.success)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h2", {
              innerHTML: $setup.t('headline_copy')
            }, null, 8, _hoisted_3),
            _createElementVNode("p", {
              class: "sub-heading",
              innerHTML: $setup.t('body_copy')
            }, null, 8, _hoisted_4),
            ($data.exists)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "error error-message",
                  innerHTML: $setup.t('error_copy_1')
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true),
            _createVNode(_component_EmailForm, {
              onInput: _cache[0] || (_cache[0] = $event => ($data.exists = false)),
              onSubmitted: _cache[1] || (_cache[1] = $event => ($options.sendPasswordResetRequest($event)))
            })
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("h2", null, null, 512), [
                [_directive_t, 'forgot_password_confirm:headline_copy']
              ]),
              _withDirectives(_createElementVNode("p", _hoisted_7, null, 512), [
                [_directive_t, 'forgot_password_confirm:body_copy']
              ])
            ]),
            _withDirectives(_createElementVNode("a", {
              href: "#",
              "data-e2e": "send-again",
              onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($data.success = false), ["prevent"]))
            }, null, 512), [
              [_directive_t, 'forgot_password_confirm:send_again']
            ])
          ], 64)),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "error-modal-close",
        class: "close-btn",
        onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("img", {
          src: _imports_0,
          class: "close-btn-img",
          alt: "Close"
        }, null, -1)
      ]))
    ])
  ]))
}
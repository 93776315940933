import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "earn-drops" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { key: 3 }

import { useI18next } from '@composables/i18next';
    import { TheDrop, CheckmarkCircle } from '@components/svgs';

    
export default {
  __name: 'EarnDrops',
  props: {
        points: {
            type: String,
            required: true,
        },
        completed: {
            type: Boolean,
            required: true,
        },
        showText: {
            type: Boolean,
            required: true,
        },
    },
  setup(__props) {

    const { t, vT } = useI18next(['profile']);
    

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("p", _hoisted_1, [
    (!__props.completed)
      ? (_openBlock(), _createBlock(_unref(TheDrop), { key: 0 }))
      : _createCommentVNode("", true),
    (__props.showText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(t)('profile:earn')), 1))
      : (_openBlock(), _createBlock(_unref(CheckmarkCircle), { key: 2 })),
    _createElementVNode("span", {
      textContent: _toDisplayString(__props.points)
    }, null, 8, _hoisted_3),
    (__props.showText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(t)('profile:drop')), 1))
      : _createCommentVNode("", true)
  ]))
}
}

}
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useAnimation () {
    const store = useStore();
    const ui = computed(() => store.state.ui);

    const animated = computed(() => !ui.value.reduceAnimation);
    return { animated };
}

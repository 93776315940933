
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18next } from './i18next';

export function useComponent (copyNodes) {
    const { t, vT } = useI18next(copyNodes);
    const store = useStore();
    const router = useRouter();

    return {
        store,
        t,
        vT,
        router,
    };
}

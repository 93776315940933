
import { reactive } from 'vue';
import { makeCall } from '@utils/api';

const getDefaultState = () => reactive({
    activePromotions: [],
    usersPromotions: [],
    sweepsEntries: {},
});

const state = getDefaultState();

const getters = {
    promotionData (state) {
        return (id) => state.activePromotions.find((promotion) => promotion.name === `bluetriton_${id}`) || {};
    },
    isActivePlay (state) {
        return (id) => state.activePromotions.find((promotion) => promotion.slug === id && promotion.hasPlayPage);
    },
    needsOptin (state) {
        return state.activePromotions.filter((activePromo) => !state.usersPromotions.find((userPromo) => activePromo.name === userPromo));
    },
    userSweepsCount (state) {
        return (promo) => state.sweepsEntries[promo];
    },
};

const mutations = {
    updateActivePromotions (state, promotions) {
        state.activePromotions = [...promotions];
    },
    updateUsersPromotions (state, promotions) {
        state.usersPromotions = [...promotions];
    },
    updateSweeps (state, { promo, totalSweeps }) {
        state.sweepsEntries[promo] = totalSweeps;
    },
};

const actions = {
    async getActivePromotions ({ state, commit }, force) {
        if (state.activePromotions.length > 0 && !force) return;

        const response = await makeCall({
            type: 'get',
            endpoint: 'promotions/active-promotions',
        });
        commit('updateActivePromotions', response.data?.promotions);

        return response.data?.promotions.length;
    },
    async getUsersPromotions ({ state, commit }, force) {
        if (state.usersPromotions.length > 0 && !force) return;

        const response = await makeCall({
            type: 'get',
            endpoint: 'profiles/subPromos',
        });
        commit('updateUsersPromotions', response.data?.promotions);

        return response.data?.promotions.length;
    },
    async sendOptins ({ state, commit }, deploys) {
        const response = await makeCall({
            endpoint: 'profiles/subPromoRegister',
            data: { deploys },
        });
        commit('updateUsersPromotions', [...state.usersPromotions, deploys]);

        return response;
    },

    async getUserSweepsEntries ({ commit, getters, rootGetters }, promo) {
        if (!rootGetters['profile/loggedIn']) return;

        try {
            const { data } = await makeCall({
                endpoint: `profiles/sweepsCount/${promo}`,
                type: 'get',
            });
            commit('updateSweeps', { promo, totalSweeps: data.totalSweeps });
        }
        catch (error) {
            console.error('Error', error);
        }

    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};


    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import * as FormFields from '@components/form';
    import { updateAddress as validators } from '../../../lib/validation/client-validators';
    import BaseModal from './BaseModal';


    export default {
        name: 'AddressUpdateModal',

        components: {
            ...FormFields,
        },

        extends: BaseModal,

        setup () {
            const { t } = useI18next(['redeem_confirmation', 'profile', 'registration', 'buttons', 'links']);
            const store = useStore();
            const profile = computed(() => store.state.profile);

            const defaultProfile = computed(() => ({
                address1: profile.value.address1,
                address2: profile.value.address2,
                city: profile.value.city,
                state: `US-${profile.value.state}`,
                zip: profile.value.zip,
            }));

            return { t, profile, defaultProfile, store };
        },

        data () {
            return {
                modalName: 'session-expiration',
                validators,
                tempProfile: {
                    ...this.defaultProfile,
                },
                fields: [
                    {
                        type: 'InputWithValidation',
                        name: 'address1',
                    },
                    {
                        type: 'BaseInput',
                        name: 'address2',
                    },
                    {
                        type: 'InputWithValidation',
                        name: 'city',
                    },
                    {
                        type: 'StateSelect',
                        name: 'state',
                    },
                    {
                        type: 'InputWithValidation',
                        name: 'zip',
                    },
                ],
            };
        },

        computed: {
            hasFullAddress () {
                const { address1, city, state, zip } = this.profile;
                return address1 && city && state && zip;
            },
            whichCopy () {
                return (this.hasFullAddress) ? 'update_address' : 'no_address';
            },
        },

        methods: {
            async updateProfile () {
                try {
                    await this.store.dispatch('profile/updateCurrentProfile', this.tempProfile);
                    this.closeModal();
                }
                catch (error) {
                    console.error('Error', error);
                }
            },
            handleValidation () {
                console.log('>>>>');
            },
        },
    };

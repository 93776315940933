
import { nextTick, ref, toValue } from 'vue';
import { useStore } from 'vuex';

import { track } from '../plugins/WebAnalytics';


export function useDialog (dialogName, dialogRef, persistent = true) {
    const store = useStore();

    const isModal = ref(false);
    const isOpen = ref(false);
    const wasRejected = ref(true);

    let promiseReject = null;
    let promiseResolve = null;

    function cancel (value) {
        const dialogEl = toValue(dialogRef);
        dialogEl.close(value);
    }

    function close (value) {
        wasRejected.value = false;

        const dialogEl = toValue(dialogRef);
        dialogEl.close(value);
    }

    function handleClose () {
        nextTick(() => {
            document.body.removeAttribute('data-e2e-dialog');
            store.commit('ui/dialogClose');
            track('Close', {
                category: isModal.value ? 'Modal' : 'Dialog',
                label: dialogName,
            });

            isOpen.value = false;

            const dialogEl = toValue(dialogRef);
            if (wasRejected.value) {
                promiseReject(dialogEl.returnValue);
            }
            else {
                promiseResolve(dialogEl.returnValue);
            }
        });
    }

    function handleOpen (modal = false) {
        document.body.setAttribute('data-e2e-dialog', dialogName);
        store.commit('ui/dialogOpen', {
            dialogName,
            isModal: modal,
        });
        track('Open', {
            category: modal ? 'Modal' : 'Dialog',
            label: dialogName,
        });

        isModal.value = modal;
        isOpen.value = true;
        wasRejected.value = true;

        // Hook into dialog element's native close event, and use nextTick to ensure the `close`
        // method (defined above) can set `wasRejected` to false before this handler fires.
        const dialogEl = toValue(dialogRef);
        dialogEl.addEventListener('close', handleClose, { once: true });

        return new Promise((resolve, reject) => {
            promiseReject = reject;
            promiseResolve = resolve;
        });
    }

    function show () {
        const dialogEl = toValue(dialogRef);
        dialogEl.show();
        return handleOpen();
    }

    function showModal () {
        const dialogEl = toValue(dialogRef);
        dialogEl.showModal();
        return handleOpen(true).catch((error) => null);
    }

    return {
        cancel,
        close,
        isOpen,
        show,
        showModal,
    };
}

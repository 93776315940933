import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@public/img/svgs/ozarka/white.svg'
import _imports_1 from '@public/img/svgs/ozarka/full.svg'


const _hoisted_1 = {
  key: 0,
  class: "ozarka-logo",
  src: _imports_0,
  alt: "Ozarka Logo"
}
const _hoisted_2 = {
  key: 1,
  class: "ozarka-logo",
  src: _imports_1,
  alt: "Ozarka Logo"
}


export default {
  __name: 'OzarkaLogo',
  props: {
        version: {
            type: [String, undefined],
            default: undefined,
        },
    },
  setup(__props) {


    


return (_ctx, _cache) => {
  return (__props.version === 'white')
    ? (_openBlock(), _createElementBlock("img", _hoisted_1))
    : (_openBlock(), _createElementBlock("img", _hoisted_2))
}
}

}
import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["aria-label", "title"]
const _hoisted_2 = {
  value: "",
  disabled: ""
}
const _hoisted_3 = ["value"]
const _hoisted_4 = { for: "state" }

import { computed, ref, onBeforeMount, watch } from 'vue';
    import axios from 'axios';
    import { useI18next } from '@composables/i18next';
    import { useAnimation } from '@composables/animation';
    import { states } from '../../utils/states';
    import api from '../../api';

    
export default {
  __name: 'StateSelect',
  props: /*@__PURE__*/_mergeModels({
        name: {
            type: String,
            required: true,
        },
    }, {
    "modelValue": { type: String, required: true },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['validation'], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {

    const emit = __emit;
    const props = __props;

    const { t } = useI18next(['profile', 'global']);
    const eligibleStates = ref([]);

    const { animated } = useAnimation();

    onBeforeMount(async () => {
        try {
            const { data } = await axios.get(`${api.base}/eligible-states`);
            eligibleStates.value = data.states;
        }
        catch (error) {
            eligibleStates.value = states;
        }
    });

    const filteredEligibleStates = computed(() =>  eligibleStates.value.map(state => ({
        label: t(`global:state.${state}.abbrev`) || state,
        value: state,
    })));

    const model = _useModel(__props, "modelValue");

    watch(model, (newValue, oldValue) => {
        emit('validation', { name: props.name, isValid: newValue !== '' });
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "state",
    class: _normalizeClass(["base-select field", { error: false, animated: _unref(animated), valued: model.value !== '', valid: model.value }])
  }, [
    _withDirectives(_createElementVNode("select", {
      ref: "select",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
      name: "state",
      "aria-label": _unref(t)('field.state'),
      title: _unref(t)('field.state')
    }, [
      _createElementVNode("option", _hoisted_2, _toDisplayString(_unref(animated) ? '' : _ctx.label), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredEligibleStates.value, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          key: `${option.value}`,
          value: option.value
        }, _toDisplayString(option.label), 9, _hoisted_3))
      }), 128))
    ], 8, _hoisted_1), [
      [_vModelSelect, model.value]
    ]),
    _createElementVNode("label", _hoisted_4, _toDisplayString(_unref(t)('profile:field.state')), 1)
  ], 2))
}
}

}
import { reactive } from 'vue';
import { makeCall } from '@utils/api';

const userEarnAchievementsDefault = {
    achievementName: 'tell_a_friend',
    properties: {
        type: 'invite_friend',
    },
};

const getDefaultState = () => reactive({
    activeAchievements: [],
    userEarnAchievements: [],
});

const state = getDefaultState();

const getters = {
    getAchievementData (state) {
        return (achievement) => {
            if (state.activeAchievements.length === 0) return {};
            const properties = state.activeAchievements.find((activeAchievement) => achievement === activeAchievement.achievementName);
            return properties;
        };
    },
};

const mutations = {
    updateAchievements (state, achievements) {
        state.activeAchievements = [...achievements];
    },
    updateUserEarnAchievements (state, achievements) {
        state.userEarnAchievements = [...achievements];
    },
    updateSingleAchievement (state, achievementId) {
        const index = state.activeAchievements.findIndex((achievement) => achievement.achievementName === achievementId);
        state.activeAchievements[index].results.completed = true;
    },
};

const actions = {
    async getUserAchievements ({ state, dispatch, commit }, force) {
        if (state.activeAchievements.length > 0 && !force) return;

        const response = await makeCall({
            type: 'get',
            endpoint: 'achievements/checkAll',
        });

        commit('updateAchievements', response.data);

        return response.data;
    },
    async updateAchievement ({ commit, rootState }, achievementId) {
        try {
            const response = await makeCall({
                endpoint: `achievements/apply/${achievementId}`,
            });

            commit('updateSingleAchievement', achievementId);
            commit('profile/updateProfile', { balance: rootState.profile.balance + response.data.properties.points }, { root: true });
        }
        catch (error) {
            console.error('Error:', error);
        }
    },
    async applyProfileAchievement ({ state, commit, rootState, getters }, achievementId) {
        const data = getters.getAchievementData(achievementId);
        if (!data.results.completed) {
            commit('updateSingleAchievement', achievementId);
            commit('profile/updateProfile', { balance: rootState.profile.balance + data.properties.points }, { root: true });
        }

    },
    async getUserEarnAchievements ({ commit, rootState }) {
        try {
            const response = await makeCall({
                type: 'get',
                endpoint: `achievements/user/${rootState.profile.preferred_brand}`,
            });

            const filteredResults = response.data.filter((element) => {
                const activity = element;
                if (activity.results?.activeStatus) {
                    return activity.results?.activeStatus === 'active';
                }
                return true;
            });

            commit('updateUserEarnAchievements', [...filteredResults]);
        }
        catch (error) {
            console.error('Error', error);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "promotion-list-item" }
const _hoisted_2 = { class: "left-column" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "right-column" }
const _hoisted_5 = { class: "headline-copy-med-2" }
const _hoisted_6 = { class: "body-copy-sm" }
const _hoisted_7 = { class: "body_copy" }
const _hoisted_8 = { class: "disclaimer" }
const _hoisted_9 = { class: "headline-copy-med-2" }
const _hoisted_10 = { class: "brand-container" }

import { ref, computed } from 'vue';
    import { useComponent } from '@composables/component';
    import * as Brands from '@components/logos';

    
export default {
  __name: 'PromotionListItem',
  props: {
        promotionId: {
            type: String,
            required: true,
        },
    },
  setup(__props) {

    

    const { t, vT, store } = useComponent(['promotions', 'buttons']);

    const promoname = ref(__props.promotionId.split('_')[1]);
    const promotionData = computed(() => store.getters['promotions/promotionData'](promoname.value));

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: `/public/img/promotion/${promoname.value}/promotion-list-item.webp`,
        alt: _unref(t)(`${promoname.value}.image.alt`)
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("h2", _hoisted_5, null, 512), [
        [_unref(vT), `${promoname.value}.headline`]
      ]),
      _withDirectives(_createElementVNode("p", _hoisted_6, null, 512), [
        [_unref(vT), `${promoname.value}.date_range`]
      ]),
      _withDirectives(_createElementVNode("p", _hoisted_7, null, 512), [
        [_unref(vT), `${promoname.value}.body`]
      ]),
      _withDirectives(_createVNode(_component_router_link, {
        to: `promotion/${promoname.value}`,
        class: "button"
      }, null, 8, ["to"]), [
        [_unref(vT), 'buttons:learn_more']
      ]),
      _withDirectives(_createElementVNode("p", _hoisted_8, null, 512), [
        [_unref(vT), { key: `${promoname.value}.disclaimer`, rulesUrl: promotionData.value?.siteUrl }]
      ]),
      _withDirectives(_createElementVNode("h2", _hoisted_9, null, 512), [
        [_unref(vT), 'brand_headline']
      ]),
      _createElementVNode("div", _hoisted_10, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(promotionData.value?.eligibleBrands, (brand) => {
          return (_openBlock(), _createElementBlock("span", {
            key: brand,
            class: "brand-img-wrapper"
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Brands[brand])))
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

}
import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"
import _imports_0 from '@public/img/close.png'


const _hoisted_1 = {
  class: "modal update-password",
  role: "dialog"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "error error-message"
}
const _hoisted_4 = {
  key: 1,
  class: "error error-message"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { key: 1 }

import { reactive, ref } from 'vue';
    import { useI18next } from '@composables/i18next';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { RenderlessValidator } from 'rocketship-validator';
    import PasswordInputWithValidation from '@components/form/PasswordInputWithValidation';
    import { ResetPassword as validatorGuards } from '../../../lib/validation/client-validators';
    import openModal, { ErrorModal } from '../modals';
    import BaseModal from './BaseModal';

    
export default /*@__PURE__*/Object.assign({
        extends: BaseModal,
    }, {
  __name: 'UpdatePasswordModal',
  props: {
        tempProfileId: {
            type: String,
            required: true,
            default: '',
        },
        tempToken: {
            type: String,
            required: false,
            default: '',
        },
    },
  emits: ['password-updated'],
  setup(__props, { emit: __emit }) {

    
    const router = useRouter();
    const { t, vT } = useI18next(['profile', 'buttons', 'modal_content', 'error']);
    const emit = __emit;
    const store = useStore();
    const props = __props;

    const tempProfile = reactive({
        id: props.tempProfileId || '',
        token: props.tempToken || '',
        password: '',
    });

    const loading = ref(false);
    const passwordUpdated = ref(false);
    const hasErrors = ref(false);
    const errorMessage = ref('');
    const handlePasswordErrors = (errors) => {
        const passwordErrors = errors.filter(e => e.name === 'password');

        if (passwordErrors.length > 0) {
            errorMessage.value = t('profile:password_requirements_error');
            hasErrors.value = true;
        }
    };

    const updatePassword = async () => {
        loading.value = true;
        errorMessage.value = '';
        try {
            await store.dispatch('profile/resetPassword', { tempProfile });
            passwordUpdated.value = true;
            emit('password-updated');
        }
        catch (error) {

            if (error.response && error.response.data && error.response.data.error === 'old-password-match') {
                errorMessage.value = t('old_password_text');
            }
            else if (error.response && error.response.data && error.response.data.error === 'bad-token') {
                errorMessage.value = t('bad-token');
            }
            else if (error.response && error.response.data && error.response.data.errors) {
                errorMessage.value = t('password_req_error');
            }
            else {
                errorMessage.value = error.message || t('error:body_copy');
            }
        }
        finally {
            loading.value = false;
        }
    };

    const updateValidation = (validation) => {
        hasErrors.value = validation.hasErrors;
    };

    const closeModal = () => {
        tempProfile.password = '';
        emit('close');
        router.push('/landing');
    };

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay profile-modal",
    onClick: _cache[4] || (_cache[4] = _withModifiers($event => (closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!passwordUpdated.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _withDirectives(_createElementVNode("h2", null, null, 512), [
              [_unref(vT), 'profile:password_update_title']
            ]),
            _withDirectives(_createElementVNode("p", null, null, 512), [
              [_unref(vT), 'profile:password_update_subtitle']
            ]),
            _createVNode(_unref(RenderlessValidator), {
              ref: "validator",
              guards: _unref(validatorGuards),
              data: tempProfile,
              class: "password-update-form"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(PasswordInputWithValidation), {
                  modelValue: tempProfile.password,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((tempProfile.password) = $event)),
                  requirements: [
                            'minChars',
                            'minLetters',
                            'minNums',
                            'specialChars',
                        ],
                  error: _unref(t)('ada.errors.password'),
                  name: "password",
                  label: "New Password",
                  validator: _unref(validatorGuards).password,
                  onValidation: updateValidation
                }, null, 8, ["modelValue", "error", "validator"]),
                (errorMessage.value)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(errorMessage.value), 1))
                  : _createCommentVNode("", true),
                (hasErrors.value)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref(t)('profile:error_copy', 'Please fix the errors')), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("button", {
                  type: "submit",
                  class: _normalizeClass(["btn btn_update_password", { loading: loading.value }]),
                  disabled: loading.value,
                  "data-e2e": "update-password-submit",
                  onClick: _withModifiers(updatePassword, ["prevent"])
                }, _toDisplayString(_unref(t)('buttons:submit')), 11, _hoisted_5)
              ]),
              _: 1
            }, 8, ["guards", "data"]),
            _createElementVNode("a", {
              href: "#",
              "data-e2e": "error-modal-close",
              onClick: _cache[1] || (_cache[1] = _withModifiers($event => (closeModal()), ["prevent"]))
            }, _toDisplayString(_unref(t)('buttons:close', 'Close')), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("h2", null, _toDisplayString(_unref(t)('modal_content:password_success.heading')), 1),
            _createElementVNode("p", null, _toDisplayString(_unref(t)('modal_content:password_success.body')), 1),
            _createElementVNode("button", {
              class: "btn",
              onClick: _cache[2] || (_cache[2] = _withModifiers($event => (closeModal()), ["self"]))
            }, _toDisplayString(_unref(t)('buttons:login')), 1)
          ])),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "error-modal-close",
        class: "close-btn",
        onClick: _cache[3] || (_cache[3] = _withModifiers($event => (closeModal()), ["prevent"]))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("img", {
          src: _imports_0,
          class: "close-btn-img",
          alt: "Close"
        }, null, -1)
      ]))
    ])
  ]))
}
}

})
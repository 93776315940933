import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed-navigation" }

import { computed } from 'vue';
    import { useI18next } from '@composables/i18next';
    import { useStore } from 'vuex';
    import { TheDrop, EarnStar, RedeemGift } from '@components/svgs';


    
export default {
  __name: 'FixedNavigation',
  setup(__props) {

    const { t, vT } = useI18next(['navigation']);
    const store = useStore();

    const profile = computed(() => store.state.profile);
    store.dispatch('profile/getUserBalance', true);

return (_ctx, _cache) => {
  const _component_RouterLink = _resolveComponent("RouterLink")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_RouterLink, {
      to: "/earn",
      class: "earn-link",
      title: _unref(t)('fixed_navigation.earn')
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(EarnStar)),
        _withDirectives(_createElementVNode("span", null, null, 512), [
          [_unref(vT), 'fixed_navigation.earn']
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_RouterLink, {
      to: "/profile/account",
      class: "profile-link",
      title: _unref(t)('fixed_navigation.profile')
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(TheDrop)),
        _createElementVNode("p", null, [
          _createElementVNode("span", null, _toDisplayString(profile.value.balance || '0'), 1),
          _createTextVNode(_toDisplayString(_unref(t)('fixed_navigation.profile')), 1)
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_RouterLink, {
      to: "/redeem",
      class: "redeem-link",
      title: _unref(t)('fixed_navigation.redeem')
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(RedeemGift)),
        _withDirectives(_createElementVNode("span", null, null, 512), [
          [_unref(vT), 'fixed_navigation.redeem']
        ])
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}
}

}
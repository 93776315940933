
import store from '../store';

// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    DuplicateAccountPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/DuplicateAccountPage.vue'
    ),
    HowItWorks = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/HowItWorks.vue'
    ),
    HomePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/HomePage.vue'
    ),
    EarnPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/EarnPage.vue'
    ),
    LandingPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LandingPage.vue'
    ),
    ProfilePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/ProfilePage.vue'
    ),
    RedeemPage = async () => import(
        /* webpackChunkName: 'redeem' */
        '../views/RedeemPage.vue'
    ),
    RedeemConfirmationPage = async () => import(
        /* webpackChunkName: 'redeem' */
        '../views/RedeemConfirmationPage.vue'
    ),
    CatalogDetailPage = async () => import(
        /* webpackChunkName: 'reward' */
        '../views/CatalogDetailPage.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (
            store.getters['profile/loggedIn'] &&
            !store.getters['profile/isSessionExpired']
        ) {
            console.log('logged in');

            return '/home';
        }
        // Default route for a user we know nothing about.
        return '/landing';
    } },
    { path: '/home', name: 'home', component: HomePage },
    { path: '/dup_account', component: DuplicateAccountPage, meta: { public: true } },
    { path: '/how_it_works', component: HowItWorks, meta: { public: true } },
    { path: '/earn', name: 'earn', component: EarnPage },
    { path: '/registration', name: 'registration', component: LandingPage, meta: { public: true } },
    { path: '/signin', name: 'signin', component: LandingPage, meta: { public: true } },
    { path: '/landing', name: 'landing', component: LandingPage, meta: { public: true, activeInMaint: false } },
    { path: '/profile/:category?', name: 'profile', component: ProfilePage, props: true },
    { path: '/redeem_confirmation/:categoryId/:externalId/:size?', component: RedeemConfirmationPage, props: true },
    { path: '/redeem/:categoryId/:externalId', component: CatalogDetailPage, meta: { public: true }, props: true },
    { path: '/redeem', name: 'redeem', component: RedeemPage },
    { path: '/logout', name: 'logout', redirect: () => {
        store.dispatch('profile/logOut');
        return '/';
    } },
];

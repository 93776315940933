import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { unref as _unref, vModelCheckbox as _vModelCheckbox, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "name"]
const _hoisted_2 = {
  key: 0,
  class: "animation-marker"
}
const _hoisted_3 = ["for", "innerHTML"]
const _hoisted_4 = {
  key: 1,
  width: "15",
  height: "14",
  viewbox: "0 0 15 14",
  fill: "none"
}
const _hoisted_5 = {
  key: 2,
  xmlns: "http://www.w3.org/2000/svg",
  version: "1.1"
}
const _hoisted_6 = { key: 3 }
const _hoisted_7 = {
  key: 4,
  class: "error-message aca-error-message",
  role: "alert",
  "aria-live": "assertive"
}

import { ref } from 'vue';
    import { useAnimation } from '@composables/animation';

    
export default /*@__PURE__*/Object.assign({ inheritAttrs: false }, {
  __name: 'BaseCheckbox',
  props: /*@__PURE__*/_mergeModels({
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        error: {
            type: String,
            required: true,
        },
    }, {
    "modelValue": { type: Boolean, required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

    
    

    const touched = ref(false);

    const { animated } = useAnimation();

    const model = _useModel(__props, "modelValue");

    const updateTouched = () => {
        touched.value = true;
    };

return (_ctx, _cache) => {
  const _component_fegaussianblur = _resolveComponent("fegaussianblur")
  const _component_fecolormatrix = _resolveComponent("fecolormatrix")
  const _component_feblend = _resolveComponent("feblend")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-checkbox field", { error: __props.isError && touched.value, animated: _unref(animated), valued: model.value !== '' }])
  }, [
    _withDirectives(_createElementVNode("input", _mergeProps(_ctx.$attrs, {
      id: __props.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
      type: "checkbox",
      name: __props.name,
      onFocus: updateTouched
    }), null, 16, _hoisted_1), [
      [_vModelCheckbox, model.value]
    ]),
    (_unref(animated))
      ? (_openBlock(), _createElementBlock("span", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("label", {
      for: __props.name,
      innerHTML: __props.label
    }, null, 8, _hoisted_3),
    (_unref(animated))
      ? (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[1] || (_cache[1] = [
          _createElementVNode("path", { d: "M2 8.36364L6.23077 12L13 2" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (_unref(animated))
      ? (_openBlock(), _createElementBlock("svg", _hoisted_5))
      : _createCommentVNode("", true),
    (_unref(animated))
      ? (_openBlock(), _createElementBlock("defs", _hoisted_6, [
          _cache[2] || (_cache[2] = _createElementVNode("filter", { id: "goo" }, null, -1)),
          _createVNode(_component_fegaussianblur, {
            in: "SourceGraphic",
            stddeviation: "4",
            result: "blur"
          }),
          _createVNode(_component_fecolormatrix, {
            in: "blur",
            mode: "matrix",
            values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7",
            result: "goo"
          }),
          _createVNode(_component_feblend, {
            in: "SourceGraphic",
            in2: "goo"
          })
        ]))
      : _createCommentVNode("", true),
    (__props.isError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(__props.error), 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "modal",
  role: "dialog"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", {
        innerHTML: $setup.t(`${$options.whichCopy}.headline`)
      }, null, 8, _hoisted_2),
      _createElementVNode("p", {
        innerHTML: $setup.t(`${$options.whichCopy}.body`)
      }, null, 8, _hoisted_3),
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.fields, (field) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(field.type), {
            key: `field-${field.name}`,
            modelValue: $data.tempProfile[field.name],
            "onUpdate:modelValue": $event => (($data.tempProfile[field.name]) = $event),
            error: $setup.t(`ada.errors.${field.name}`),
            label: $setup.t(`field.${field.name}`),
            name: field.name,
            requirements: field.requirements,
            options: field.options,
            validator: $data.validators[field.name],
            onValidation: $options.handleValidation
          }, null, 40, ["modelValue", "onUpdate:modelValue", "error", "label", "name", "requirements", "options", "validator", "onValidation"]))
        }), 128))
      ]),
      _createElementVNode("button", {
        class: "button mb-m",
        onClick: _cache[0] || (_cache[0] = (...args) => ($options.updateProfile && $options.updateProfile(...args))),
        innerHTML: $setup.t(`${$options.whichCopy}.button`)
      }, null, 8, _hoisted_4),
      _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "session-expiration-modal-close",
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"])),
        innerHTML: $setup.t('buttons:cancel')
      }, null, 8, _hoisted_5)
    ])
  ]))
}
import axios from 'axios';
import api from '../api';

export const makeCall = async ({
    type = 'post',
    endpoint,
    data,
}) => {
    let response;

    try {
        response = await axios[type](`${api.base}/${endpoint}`, data);
    }
    catch (err) {
        if (err.response?.status === 429) {
            // User was only limited, carry on
            ({ response } = err);
        }
        else {
            console.error(
                `error making ${endpoint} call`,
                err.message,
                err,
            );

            throw err;
        }
    }

    return response;
};

import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal",
  role: "dialog"
}
const _hoisted_2 = {
  key: 0,
  class: "button-container"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RegisterForm = _resolveComponent("RegisterForm")
  const _component_LoginForm = _resolveComponent("LoginForm")

  return (!$setup.loggedIn)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["modal-overlay sticky-authenticator-component", { sticky: $setup.ui.isSticky, login: !$setup.ui.isReg, 'reg': $setup.ui.isReg }]),
        onClick: _cache[10] || (_cache[10] = _withModifiers($event => ($options.changeSticky(false)), ["self"]))
      }, [
        _createVNode(_Transition, { name: "authenticator" }, {
          default: _withCtx(() => [
            ($data.onScreen)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  ($setup.ui.isSticky)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("button", {
                          class: "button",
                          onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($options.changeSticky(false)), ["prevent"]))
                        }, _toDisplayString($setup.t('buttons:sign_in')), 1),
                        _createElementVNode("button", {
                          class: "button secondary",
                          onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($options.changeSticky(true)), ["prevent"]))
                        }, _toDisplayString($setup.t('buttons:sign_up')), 1)
                      ]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        ($setup.ui.isReg)
                          ? (_openBlock(), _createBlock(_component_RegisterForm, {
                              key: 0,
                              onLogin: _cache[2] || (_cache[2] = $event => ($options.updateRegLogin(false, false))),
                              onRegistered: _cache[3] || (_cache[3] = $event => (_ctx.$router.replace('/'))),
                              onDuplicateAccount: _cache[4] || (_cache[4] = $event => (_ctx.showReg = false)),
                              onIneligibleAge: _cache[5] || (_cache[5] = $event => (_ctx.$router.replace('/ineligible_age')))
                            }))
                          : (_openBlock(), _createBlock(_component_LoginForm, {
                              key: 1,
                              onReg: _cache[6] || (_cache[6] = $event => ($options.updateRegLogin(true, false))),
                              onLoggedIn: _cache[7] || (_cache[7] = $event => (_ctx.$router.replace('/'))),
                              onNotFound: _cache[8] || (_cache[8] = $event => ($options.changeSticky(true))),
                              onCloseModal: _cache[9] || (_cache[9] = $event => ($options.changeSticky(true)))
                            }))
                      ], 64))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 2))
    : _createCommentVNode("", true)
}
import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "value", "name"]
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "error-message aca-error-message",
  role: "alert",
  "aria-live": "assertive"
}

import { ref } from 'vue';
    import { useAnimation } from '@composables/animation';

    
export default {
  __name: 'BaseRadioGroup',
  props: /*@__PURE__*/_mergeModels({
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        error: {
            type: String,
            required: true,
        },
    }, {
    "modelValue": { type: String, required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

    

    const touched = ref(false);

    const { animated } = useAnimation();

    const model = _useModel(__props, "modelValue");

    const updateTouched = () => {
        touched.value = true;
    };

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-radio-group", { error: __props.isError && touched.value }])
  }, [
    _createElementVNode("p", null, _toDisplayString(__props.label), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
      return (_openBlock(), _createElementBlock("span", {
        key: `radio_${option.value}`,
        class: _normalizeClass(["base-radio field", { animated: _unref(animated), active: option.value === model.value }])
      }, [
        _withDirectives(_createElementVNode("input", {
          id: option.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
          type: "radio",
          value: option.value,
          name: __props.name,
          onFocus: updateTouched
        }, null, 40, _hoisted_1), [
          [_vModelRadio, model.value]
        ]),
        _createElementVNode("label", {
          for: option.value
        }, _toDisplayString(option.label), 9, _hoisted_2),
        (__props.isError)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(__props.error), 1))
          : _createCommentVNode("", true)
      ], 2))
    }), 128))
  ], 2))
}
}

}
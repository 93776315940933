import { createElementVNode as _createElementVNode, unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/error_fpo.png'
import _imports_1 from '@public/img/error_fpo_sm.png'
import _imports_2 from '@public/img/error_drop.png'
import _imports_3 from '@public/img/error_drop_sm.png'


const _hoisted_1 = { class: "page-error" }
const _hoisted_2 = { class: "text-section" }

import { useI18next } from '@composables/i18next';
    import { useBasePage } from '@composables/useBasePage';

    
export default {
  __name: 'ErrorPage',
  setup(__props) {

    const { vT } = useI18next('error');
    useBasePage('error');

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "banner-img" }, [
      _createElementVNode("img", {
        src: _imports_0,
        class: "small-only",
        alt: "banner img"
      }),
      _createElementVNode("img", {
        src: _imports_1,
        class: "large-only",
        alt: "banner img"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_2,
        class: "small-only",
        alt: "Drop Img"
      }, null, -1)),
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_3,
        class: "large-only",
        alt: "Drop Img"
      }, null, -1)),
      _withDirectives(_createElementVNode("h2", null, null, 512), [
        [_unref(vT), 'error:headline_copy']
      ]),
      _withDirectives(_createElementVNode("p", null, null, 512), [
        [_unref(vT), 'error:body_copy']
      ])
    ])
  ]))
}
}

}
import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "password-live-region hidden" }
const _hoisted_2 = {
  key: 0,
  id: "password-state-status"
}
const _hoisted_3 = { class: "password-live-region hidden" }
const _hoisted_4 = {
  key: 0,
  id: "password-state-status"
}
const _hoisted_5 = {
  key: 1,
  class: "validation-container password-validation"
}

import { ref, computed, useAttrs } from 'vue';
    import { debounce } from 'lodash';
    import { useAnimation } from '@composables/animation';
    import { useI18next } from '@composables/i18next';
    import { RenderlessValidator } from 'rocketship-validator';

    import { BaseInput } from '@components/form';

    
export default /*@__PURE__*/Object.assign({ inheritAttrs: false }, {
  __name: 'PasswordInputWithValidation',
  props: /*@__PURE__*/_mergeModels({
        error: {
            type: String,
            required: true,
        },
        confirmLabel: {
            type: String,
            required: false,
            default: 'confirmation field needs copy',
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        validator: {
            type: Object,
            required: true,
        },
        requirements: {
            type: Array,
            default: () => [],
        },
        useConfirm: {
            type: Boolean,
            default: false,
        },
    }, {
    "password": { type: String, required: true },
    "passwordModifiers": {},
    "password_confirm": { type: String, required: true, default: '' },
    "password_confirmModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['validation'], ["update:password", "update:password_confirm"]),
  setup(__props, { emit: __emit }) {

    
    const emit = __emit;
    const props = __props;

    const { vT } = useI18next(['profile']);
    const touched = ref(false);
    const visible = ref(false);
    const visibleConfirm = ref(false);
    const statusVisible = ref(false);
    const statusVisibleConfirm = ref(false);

    const { animated } = useAnimation();

    const attrs = useAttrs();
    const password = _useModel(__props, 'password');
    password.value = attrs.modelValue;
    const passwordConfirm = _useModel(__props, 'password_confirm');

    const guardsToUse = ref({
        [props.name]: props.validator,
    });

    if (props.useConfirm) {
        guardsToUse.value[`${props.name}`] = props.validator[`${props.name}`];
        guardsToUse.value[`${props.name}_confirm`] = props.validator[`${props.name}_confirm`];
    }

    const onInput = debounce((event) => {
        touched.value = true;
        validate();
    }, 600);

    const updateVisible = () => {
        visible.value = !visible.value;

        statusVisible.value = true;

        setTimeout(() => { statusVisible.value = false; }, 500);
    };

    const updateVisibleConfirm = () => {
        visibleConfirm.value = !visibleConfirm.value;

        statusVisibleConfirm.value = true;

        setTimeout(() => { statusVisibleConfirm.value = false; }, 500);
    };

    const isValid = ref(false);
    const tempProfile = ref({});
    const renderlessValidator = ref(null);
    const validate = async () => {
        if (password.value || (props.useConfirm && password.value && passwordConfirm.value)) {
            tempProfile.value[props.name] = password;
            if (props.useConfirm) {
                tempProfile.value[props.name + '_confirm'] = passwordConfirm;
            }
            await renderlessValidator.value.validate();
            isValid.value = renderlessValidator.value.isValid;
        }
        // don't be fooled, we don't care about confirm field outside of this component!
        // only return the main named component with the sum of whether fields are both valid or not
        emit('validation', { name: props.name, isValid: renderlessValidator.value.isValid });
    };
    const allErrors = computed(() => {
        if (!renderlessValidator.value || props.requirements.length === 0) {
            return [];
        }
        const fieldErrors = renderlessValidator.value.allErrors
            .filter((error) => (error.name === props.name || (props.useConfirm && error.name === props.name + '_confirm')))
            .map((error) => error.error);
        return fieldErrors;
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(_unref(RenderlessValidator), {
    ref_key: "renderlessValidator",
    ref: renderlessValidator,
    guards: guardsToUse.value,
    data: tempProfile.value
  }, {
    default: _withCtx(({
            isError,
        }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["base-input password field", { error: isError(__props.name) && touched.value, animated: _unref(animated), valued: password.value !== '', valid: isValid.value }])
      }, [
        _createVNode(_unref(BaseInput), _mergeProps(_ctx.$attrs, {
          id: __props.name,
          modelValue: password.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((password).value = $event)),
          label: __props.label,
          name: __props.name,
          placeholder: _unref(animated) ? '' : __props.label,
          "aria-required": "true",
          required: "",
          type: visible.value ? 'text' : 'password',
          "field-classes": '',
          onFocus: _ctx.updateTouched,
          onInput: _unref(onInput)
        }), null, 16, ["id", "modelValue", "label", "name", "placeholder", "type", "onFocus", "onInput"]),
        _createElementVNode("button", {
          type: "button",
          class: "btn-visible",
          onClick: updateVisible
        }, [
          _createElementVNode("span", null, [
            _createElementVNode("i", {
              class: _normalizeClass(["fas view-password", visible.value ? 'fa-eye-slash' : 'fa-eye'])
            }, null, 2)
          ])
        ]),
        _createElementVNode("output", _hoisted_1, [
          (statusVisible.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(visible.value ? 'Password is currently visible' : 'Password is currently hidden'), 1))
            : _createCommentVNode("", true)
        ])
      ], 2),
      (props.useConfirm)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["base-input password password-confirm field", { error: isError(`${__props.name}_confirm`) && touched.value, animated: _unref(animated), valued: passwordConfirm.value !== '', valid: isValid.value }])
          }, [
            _createVNode(_unref(BaseInput), {
              id: `${__props.name}_confirm`,
              modelValue: passwordConfirm.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((passwordConfirm).value = $event)),
              "field-classes": '',
              label: props.confirmLabel,
              name: `${__props.name}_confirm`,
              placeholder: _unref(animated) ? '' : __props.label,
              "aria-required": "true",
              required: "",
              type: visibleConfirm.value ? 'text' : 'password',
              onFocus: _ctx.updateTouched,
              onInput: _unref(onInput)
            }, null, 8, ["id", "modelValue", "label", "name", "placeholder", "type", "onFocus", "onInput"]),
            _createElementVNode("button", {
              type: "button",
              class: "btn-visible",
              onClick: updateVisibleConfirm
            }, [
              _createElementVNode("span", null, [
                _createElementVNode("i", {
                  class: _normalizeClass(["fas view-password", visibleConfirm.value ? 'fa-eye-slash' : 'fa-eye'])
                }, null, 2)
              ])
            ]),
            _createElementVNode("output", _hoisted_3, [
              (statusVisibleConfirm.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(visibleConfirm.value ? 'Password confirmation is currently visible' : 'Password confirmation is currently hidden'), 1))
                : _createCommentVNode("", true)
            ])
          ], 2))
        : _createCommentVNode("", true),
      (__props.requirements.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _withDirectives(_createElementVNode("p", null, null, 512), [
              [_unref(vT), 'password_validation.title']
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.requirements, (requirement) => {
              return (_openBlock(), _createElementBlock("div", {
                key: requirement,
                class: _normalizeClass([{ error: allErrors.value.includes(requirement), valid: !allErrors.value.includes(requirement) && touched.value }, "validation-item"])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["icon-status", { error: allErrors.value.includes(requirement), valid: !allErrors.value.includes(requirement) && touched.value }])
                }, null, 2),
                _withDirectives(_createElementVNode("p", null, null, 512), [
                  [_unref(vT), `password_validation.${requirement}`]
                ])
              ], 2))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["guards", "data"]))
}
}

})

/* This is client-side code. */
/* eslint-env browser */

const
    { isFilled } = require('rocketship-validator'),
    base = require('./validators');

module.exports = {
    ...base,
    register: {
        ...base.register,
        'g-recaptcha-response-v3': { requireCaptcha },
    },
    forgotpassword: base.forgotpassword,
    login: base.login,
    viral: base.viral,
    faqContact: base.faqContact,
    passwordReset: base.passwordReset,
    updateName: base.updateName,
    updateAddress: base.updateAddress,
    updatePassword: base.updatePassword,
    readyRefreshAdd: base.readyRefreshAdd,
};

function requireCaptcha (value, field, form) {
    if (!isFilled(value)) {
        // Error out early if we don't have a response.
        this.addError(field, 'NULL');
        return false;
    }
    return true;
}

import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "promotion-media-section" }
const _hoisted_2 = { class: "promotion-headline-copy" }
const _hoisted_3 = {
  key: 0,
  class: "logo-container"
}
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "video-container" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "additional-copy" }

import { useI18next } from '@composables/i18next';

    
export default {
  __name: 'PromotionVideo',
  props: {
        promotion: {
            type: String,
            default: '',
        },
    },
  setup(__props) {

    

    const { t, vT } = useI18next(`promotion_${__props.promotion}`);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("h2", _hoisted_2, null, 512), [
      [_unref(vT), 'video.headline_copy']
    ]),
    (_unref(t)(`assets.video.image_src_1`))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _unref(t)(`assets.video.image_src_1`),
            alt: _unref(t)(`assets.video.image_alt_1`)
          }, null, 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("iframe", {
        width: "100%",
        height: "100%",
        src: _unref(t)(`assets.video.video_src`),
        title: "YouTube video player",
        frameborder: "0",
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        referrerpolicy: "strict-origin-when-cross-origin",
        allowfullscreen: ""
      }, null, 8, _hoisted_6)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_7, null, 512), [
      [_unref(vT), 'video.additional_copy']
    ])
  ]))
}
}

}
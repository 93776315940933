import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "header",
  role: "banner",
  "data-e2e": "global-header"
}

import { ref, onMounted } from 'vue';
    import { useI18next } from '@composables/i18next';

    
export default {
  __name: 'TheHeader',
  setup(__props) {

    const { t } = useI18next(['global']);

    // data
    const h1 = ref(null);

    // Focus the h1 element to force screen readers back to the top after a navigation.
    // This will ensure screen readers will read the page again.
    // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
    onMounted(() => h1.value.focus());

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("h1", {
      ref_key: "h1",
      ref: h1,
      tabindex: "-1"
    }, _toDisplayString(_unref(t)('global:title')), 513)
  ]))
}
}

}
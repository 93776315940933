import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-expanded"]

import { computed } from 'vue';
    import { useStore } from 'vuex';
    
export default {
  __name: 'HamburgerButton',
  props: {
        open: {
            type: Boolean,
            default: false,
        },
        inBar: {
            type: Boolean,
            default: false,
        },
    },
  setup(__props) {

    const store = useStore();
    const ui = computed(() => store.state.ui);

    

    const handleBarColor = computed(() => (ui.value.pageName === 'landing') && !__props.inBar);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["hamburger-button", [{ opened: __props.open }, { 'dark-bar': handleBarColor.value }]]),
    "aria-label": "Hamburger Menu",
    "aria-expanded": __props.open
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("svg", {
      class: "bars",
      viewBox: "0 0 100 100"
    }, [
      _createElementVNode("path", {
        class: "line line1",
        d: "M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
      }),
      _createElementVNode("path", {
        class: "line line2",
        d: "M 20,50 H 80"
      }),
      _createElementVNode("path", {
        class: "line line3",
        d: "M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
      })
    ], -1)
  ]), 10, _hoisted_1))
}
}

}
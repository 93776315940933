import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-sitekey"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["g-recaptcha-response-container", { error: $props.isError }])
  }, [
    _withDirectives(_createElementVNode("p", { class: "g-recaptcha-inline-error" }, _toDisplayString($props.label), 513), [
      [_vShow, $props.isError]
    ]),
    _createElementVNode("div", {
      ref: "gRecaptcha",
      class: "g-recaptcha",
      "data-callback": "recaptchaComplete",
      "data-expired-callback": "recaptchaExpired",
      "data-sitekey": $options.recaptchaV2PublicKey
    }, null, 8, _hoisted_1)
  ], 2))
}
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "15",
  height: "16",
  viewBox: "0 0 15 16",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { "clip-path": "url(#clip0_3426_35932)" }, [
      _createElementVNode("path", {
        d: "M7.5 2.32581C9.11616 2.32581 10.6661 2.96782 11.8089 4.11062C12.9517 5.25342 13.5938 6.80339 13.5938 8.41956C13.5938 10.0357 12.9517 11.5857 11.8089 12.7285C10.6661 13.8713 9.11616 14.5133 7.5 14.5133C5.88384 14.5133 4.33387 13.8713 3.19107 12.7285C2.04827 11.5857 1.40625 10.0357 1.40625 8.41956C1.40625 6.80339 2.04827 5.25342 3.19107 4.11062C4.33387 2.96782 5.88384 2.32581 7.5 2.32581ZM7.5 15.9196C9.48912 15.9196 11.3968 15.1294 12.8033 13.7229C14.2098 12.3163 15 10.4087 15 8.41956C15 6.43043 14.2098 4.52278 12.8033 3.11625C11.3968 1.70973 9.48912 0.919556 7.5 0.919556C5.51088 0.919556 3.60322 1.70973 2.1967 3.11625C0.790176 4.52278 0 6.43043 0 8.41956C0 10.4087 0.790176 12.3163 2.1967 13.7229C3.60322 15.1294 5.51088 15.9196 7.5 15.9196ZM10.8105 7.0426C11.0859 6.76721 11.0859 6.3219 10.8105 6.04944C10.5352 5.77698 10.0898 5.77405 9.81738 6.04944L6.56543 9.30139L5.18848 7.92444C4.91309 7.64905 4.46777 7.64905 4.19531 7.92444C3.92285 8.19983 3.91992 8.64514 4.19531 8.9176L6.07031 10.7926C6.3457 11.068 6.79102 11.068 7.06348 10.7926L10.8105 7.0426Z",
        fill: "#08A7D3"
      })
    ], -1),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "clip0_3426_35932" }, [
        _createElementVNode("rect", {
          width: "15",
          height: "15",
          fill: "white",
          transform: "translate(0 0.919556)"
        })
      ])
    ], -1)
  ])))
}

import { reactive } from 'vue';
import { makeCall } from '@public/js/utils/api';

const getDefaultState = () => reactive({
    rewardsGroups: [],
    landingFeaturedRewards: [],
});

const state = getDefaultState();

const getters = {
    getCategory (state) {
        return (categoryId) => {
            const foundCategory = state.rewardsGroups.find((rewardGroup) => rewardGroup.name.toLowerCase() === categoryId);
            return foundCategory?.rewards || [];
        };
    },
    getItemDetails (state, getters) {
        return (externalId, categoryId) => {
            const rewards = getters.getCategory(categoryId);
            const item = rewards.find((reward) => reward.external_id === externalId);
            return item;
        };
    },
};

const mutations = {
    updateItems (state, rewards) {
        state.rewardsGroups = rewards;
    },
    updateLandingFeatured (state, rewards) {
        state.landingFeaturedRewards = rewards;
    },
    updateBalance (state, balance) {
        state.balance = balance;
        state.fetchedBalance = true;
    },
};

const actions = {
    async getRewardItems ({ commit }) {
        try {
            const response = await makeCall({
                type: 'get',
                endpoint: 'catalog',
            });

            commit('updateItems', response.data.rewards);
        }
        catch (error) {
            console.error('Error no items available');
        }
    },
    async getFeaturedLandingCatalog ({ commit }, featuredCatalog) {
        try {
            const response = await makeCall({
                type: 'get',
                endpoint: `catalog/featured/${featuredCatalog}`,
            });

            commit('updateLandingFeatured', response.data.rewards);
        }
        catch (error) {
            console.error('Error no items available');
        }
    },
    async redeemReward ({ dispatch }, reward) {
        try {
            const response = await makeCall({
                endpoint: 'redeem',
                data: { reward },
            });
            return { data: response.data };
        }
        catch (error) {
            console.error('Error', error);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("svg", {
      class: "filled-star",
      xmlns: "http://www.w3.org/2000/svg",
      width: "23",
      height: "23",
      viewBox: "0 0 23 23",
      fill: "none"
    }, [
      _createElementVNode("path", {
        d: "M12.3218 1.67376L11.8715 1.86101L12.3218 1.67376C12.0177 0.942811 10.9823 0.942801 10.6782 1.67376L8.11383 7.83934L1.45757 8.37297C0.668446 8.43623 0.348465 9.42102 0.949693 9.93604L1.27497 9.55631L0.949694 9.93604L6.02107 14.2802L4.47168 20.7756C4.288 21.5456 5.1257 22.1543 5.80131 21.7416L11.5 18.2609L17.1987 21.7416C17.8743 22.1543 18.712 21.5457 18.5283 20.7756L16.9789 14.2802L22.0503 9.93604C22.6515 9.42103 22.3316 8.43623 21.5424 8.37297L14.8862 7.83934L12.3218 1.67376ZM16.9633 14.2149C16.9634 14.215 16.9634 14.2151 16.9634 14.2152L16.9633 14.2149Z",
        fill: "#0066B3",
        stroke: "#0066B3"
      })
    ], -1)),
    _cache[1] || (_cache[1] = _createElementVNode("svg", {
      class: "empty-star",
      xmlns: "http://www.w3.org/2000/svg",
      width: "23",
      height: "23",
      viewBox: "0 0 23 23",
      fill: "none"
    }, [
      _createElementVNode("path", {
        d: "M12.3218 1.67376L11.8715 1.86101L12.3218 1.67376C12.0177 0.942811 10.9823 0.942801 10.6782 1.67376L8.11383 7.83934L1.45757 8.37297C0.668446 8.43623 0.348465 9.42102 0.949693 9.93604L1.27497 9.55631L0.949694 9.93604L6.02107 14.2802L4.47168 20.7756C4.288 21.5456 5.1257 22.1543 5.80131 21.7416L11.5 18.2609L17.1987 21.7416C17.8743 22.1543 18.712 21.5457 18.5283 20.7756L16.9789 14.2802L22.0503 9.93604C22.6515 9.42103 22.3316 8.43623 21.5424 8.37297L14.8862 7.83934L12.3218 1.67376ZM16.9633 14.2149C16.9634 14.215 16.9634 14.2151 16.9634 14.2152L16.9633 14.2149Z",
        stroke: "#0066B3"
      })
    ], -1))
  ], 64))
}
import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { unref as _unref, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id", "name", "placeholder", "type"]
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "password-live-region hidden" }
const _hoisted_4 = {
  key: 0,
  id: "password-state-status"
}
const _hoisted_5 = {
  key: 0,
  class: "error-message aca-error-message",
  role: "alert",
  "aria-live": "assertive"
}

import { ref } from 'vue';
    import { useAnimation } from '@composables/animation';

    
export default /*@__PURE__*/Object.assign({ inheritAttrs: false }, {
  __name: 'BasePasswordInput',
  props: /*@__PURE__*/_mergeModels({
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        error: {
            type: String,
            required: true,
            default: '',
        },
    }, {
    "modelValue": { type: String, required: true },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['validation'], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {

    
    const emit = __emit;
    

    const touched = ref(false);
    const visible = ref(false);
    const statusVisible = ref(false);

    const { animated } = useAnimation();

    const model = _useModel(__props, "modelValue");

    const updateTouched = () => {
        touched.value = true;
    };
    const updateVisible = () => {
        visible.value = !visible.value;

        statusVisible.value = true;

        setTimeout(() => { statusVisible.value = false; }, 500);
    };

    emit('validation', { name: 'password', isValid: true });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-input password field", { error: __props.isError && touched.value, animated: _unref(animated), valued: model.value !== '' }])
  }, [
    _withDirectives(_createElementVNode("input", _mergeProps(_ctx.$attrs, {
      id: __props.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
      name: __props.name,
      placeholder: _unref(animated) ? '' : __props.label,
      "aria-required": "true",
      required: "",
      type: visible.value ? 'text' : 'password',
      onFocus: updateTouched
    }), null, 16, _hoisted_1), [
      [_vModelDynamic, model.value]
    ]),
    _createElementVNode("label", { for: __props.name }, _toDisplayString(__props.label), 9, _hoisted_2),
    _createElementVNode("button", {
      type: "button",
      class: "btn-visible",
      onClick: updateVisible
    }, [
      _createElementVNode("span", null, [
        _createElementVNode("i", {
          class: _normalizeClass(["fas", visible.value ? 'fa-eye-slash' : 'fa-eye'])
        }, null, 2)
      ])
    ]),
    _createElementVNode("output", _hoisted_3, [
      (statusVisible.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(visible.value ? 'Password is currently visible' : 'Password is currently hidden'), 1))
        : _createCommentVNode("", true)
    ]),
    (__props.isError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(__props.error), 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})
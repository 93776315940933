
    import { computed } from 'vue';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import LoginForm from '@components/LoginForm.vue';
    import RegisterForm from '@components/RegisterForm.vue';
    import BaseModal from './BaseModal';

    export default {
        name: 'StickyAuthenticatorModal',

        components: {
            LoginForm,
            RegisterForm,
        },

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['login', 'buttons']);
            const store = useStore();
            const ui = computed(() => store.state.ui);
            const loggedIn = computed(() => store.getters['profile/loggedIn']);
            const route = useRoute();
            return { t, vT, route, ui, loggedIn, store };
        },

        data () {
            return {
                modalName: 'authenticator',
                onScreen: false,
            };
        },

        mounted () {
            setTimeout(()=>{ this.onScreen = true; }, 500);
            this.$nextTick(() => {
                if (this.route.path === '/registration') {
                    this.store.dispatch('ui/getReadyRefreshRegistrationData', this.route.query.rrID);
                    this.changeSticky(true);
                }
                if (this.route.path === '/signin') {
                    this.changeSticky(false);
                }
            });
        },

        methods: {
            updateRegLogin (isReg, isSticky) {
                this.store.dispatch('ui/updateRegLogin', { isReg, isSticky });
            },
            changeSticky (showReg) {
                this.updateRegLogin(showReg);
                if (!this.ui.isSticky) {
                    // UPDATE TO A NON-QUERY SELECTOR
                    [...document.querySelectorAll('.hamburger-button')][0].classList.remove('dark-bar');
                }
            },
        },
    };

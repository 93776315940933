import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, computed, watch } from 'vue';
    import { useComponent } from '@composables/component';
    import { useDialog } from '@composables/dialog';
    import RewardsDropLogo from '@components/svgs/RewardsDropLogo.vue';

    const dialogName = 'get-to-goal';
    
export default {
  __name: 'GetToGoalModal',
  setup(__props) {

    const rootEl = ref();
    const { close, cancel, showModal } = useDialog(dialogName, rootEl);

    const { vT, t, store } = useComponent(['modal_content']);
    const ui = computed(() => store.state.ui);

    const goToReg = async () => {
        store.dispatch('ui/updateRegLogin', { isReg: true, isSticky: false });
        localStorage.setItem(dialogName, true);
        close();
    };

    watch(
        () => ui.value.uiFeatures['get-to-goal'],
        (newValue, oldValue) => {
            if (newValue === 'active' && !localStorage.getItem(dialogName)) {
                showModal();
            }
        },
        { immediate: true },
    );

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("dialog", {
    ref_key: "rootEl",
    ref: rootEl,
    class: "get-to-goal-dialog",
    onClick: _cache[2] || (_cache[2] = (...args) => (_unref(cancel) && _unref(cancel)(...args)))
  }, [
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = (event) => event.stopPropagation())
    }, [
      _createVNode(RewardsDropLogo),
      _withDirectives(_createElementVNode("h2", null, null, 512), [
        [_unref(vT), 'get_to_goal.headline_copy']
      ]),
      _withDirectives(_createElementVNode("p", null, null, 512), [
        [_unref(vT), 'get_to_goal.body_copy']
      ]),
      _createElementVNode("button", {
        class: "btn",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (goToReg()), ["prevent"]))
      }, _toDisplayString(_unref(t)('get_to_goal.cta')), 1)
    ])
  ], 512))
}
}

}
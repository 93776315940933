import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  width: "257",
  height: "357",
  viewBox: "0 0 257 357",
  fill: "none"
}
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 1,
  xmlns: "http://www.w3.org/2000/svg",
  width: "257",
  height: "357",
  viewBox: "0 0 257 357",
  fill: "none"
}
const _hoisted_4 = ["id"]


export default {
  __name: 'DropMask',
  props: {
        curved: {
            type: Boolean,
            default: true,
        },
        mask: {
            type: String,
            default: 'drop_mask',
        },
    },
  setup(__props) {


    


return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["masked-component", __props.mask])
  }, [
    (__props.curved)
      ? (_openBlock(), _createElementBlock("svg", _hoisted_1, [
          _createElementVNode("mask", { id: __props.mask }, _cache[0] || (_cache[0] = [
            _createElementVNode("path", {
              d: "M0.139771 224.29C0.139771 297.584 57.4473 357 128.14 357C198.832 357 256.14 297.584 256.14 224.29C256.14 104.391 104.867 0 104.867 0C86.5244 89.8333 0.139771 123.752 0.139771 224.29Z",
              fill: "#FFFFFF"
            }, null, -1)
          ]), 8, _hoisted_2)
        ]))
      : (_openBlock(), _createElementBlock("svg", _hoisted_3, [
          _createElementVNode("mask", { id: __props.mask }, _cache[1] || (_cache[1] = [
            _createElementVNode("path", {
              d: "M256 224.29C256 297.584 198.692 357 128 357C57.3075 357 0 297.584 0 224.29C0 123.752 91 90 128 0C159 89 256 123.752 256 224.29Z",
              fill: "#FFFFFF"
            }, null, -1)
          ]), 8, _hoisted_4)
        ])),
    _createElementVNode("div", {
      class: "masked-container",
      style: _normalizeStyle(`mask: url(#${__props.mask});`)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 4)
  ], 2))
}
}

}
import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { vModelCheckbox as _vModelCheckbox, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "toggle-container" }
const _hoisted_3 = ["id", "name"]
const _hoisted_4 = ["innerHTML"]

import { ref } from 'vue';

    
export default /*@__PURE__*/Object.assign({ inheritAttrs: false }, {
  __name: 'BaseToggle',
  props: /*@__PURE__*/_mergeModels({
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
    }, {
    "modelValue": { type: Boolean, required: true },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['update'], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {


    
    

    const emit = __emit;

    const toggleSwitch = ref();

    const updateToggle = () => {
        emit('update', model.value);
    };

    const model = _useModel(__props, "modelValue");


return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("label", {
    for: __props.name,
    class: "base-toggle"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", _mergeProps({
        id: __props.name,
        ref_key: "toggleSwitch",
        ref: toggleSwitch
      }, _ctx.$attrs, {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
        type: "checkbox",
        name: __props.name,
        onChange: updateToggle
      }), null, 16, _hoisted_3), [
        [_vModelCheckbox, model.value]
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "slider" }, null, -1))
    ]),
    _createElementVNode("span", {
      class: "label",
      role: "label",
      innerHTML: __props.label
    }, null, 8, _hoisted_4)
  ], 8, _hoisted_1))
}
}

})
export const states = [
    {
        value: 'US-AL',
        label: 'AL',
    },
    {
        value: 'US-AK',
        label: 'AK',
    },
    {
        value: 'US-AZ',
        label: 'AZ',
    },
    {
        value: 'US-AR',
        label: 'AR',
    },
    {
        value: 'US-CA',
        label: 'CA',
    },
    {
        value: 'US-CO',
        label: 'CO',
    },
    {
        value: 'US-CT',
        label: 'CT',
    },
    {
        value: 'US-DE',
        label: 'DE',
    },
    {
        value: 'US-DC',
        label: 'DC',
    },
    {
        value: 'US-FL',
        label: 'FL',
    },
    {
        value: 'US-GA',
        label: 'GA',
    },
    {
        value: 'US-HI',
        label: 'HI',
    },
    {
        value: 'US-ID',
        label: 'ID',
    },
    {
        value: 'US-IL',
        label: 'IL',
    },
    {
        value: 'US-IN',
        label: 'IN',
    },
    {
        value: 'US-IA',
        label: 'IA',
    },
    {
        value: 'US-KS',
        label: 'KS',
    },
    {
        value: 'US-KY',
        label: 'KY',
    },
    {
        value: 'US-LA',
        label: 'LA',
    },
    {
        value: 'US-ME',
        label: 'ME',
    },
    {
        value: 'US-MD',
        label: 'MD',
    },
    {
        value: 'US-MA',
        label: 'MA',
    },
    {
        value: 'US-MI',
        label: 'MI',
    },
    {
        value: 'US-MN',
        label: 'MN',
    },
    {
        value: 'US-MS',
        label: 'MS',
    },
    {
        value: 'US-MO',
        label: 'MO',
    },
    {
        value: 'US-MT',
        label: 'MT',
    },
    {
        value: 'US-NE',
        label: 'NE',
    },
    {
        value: 'US-NV',
        label: 'NV',
    },
    {
        value: 'US-NH',
        label: 'NH',
    },
    {
        value: 'US-NJ',
        label: 'NJ',
    },
    {
        value: 'US-NM',
        label: 'NM',
    },
    {
        value: 'US-NY',
        label: 'NY',
    },
    {
        value: 'US-NC',
        label: 'NC',
    },
    {
        value: 'US-ND',
        label: 'ND',
    },
    {
        value: 'US-OH',
        label: 'OH',
    },
    {
        value: 'US-OK',
        label: 'OK',
    },
    {
        value: 'US-OR',
        label: 'OR',
    },
    {
        value: 'US-PA',
        label: 'PA',
    },
    {
        value: 'US-RI',
        label: 'RI',
    },
    {
        value: 'US-SC',
        label: 'SC',
    },
    {
        value: 'US-SD',
        label: 'SD',
    },
    {
        value: 'US-TN',
        label: 'TN',
    },
    {
        value: 'US-TX',
        label: 'TX',
    },
    {
        value: 'US-UT',
        label: 'UT',
    },
    {
        value: 'US-VT',
        label: 'VT',
    },
    {
        value: 'US-VA',
        label: 'VA',
    },
    {
        value: 'US-WA',
        label: 'WA',
    },
    {
        value: 'US-WV',
        label: 'WV',
    },
    {
        value: 'US-WI',
        label: 'WI',
    },
    {
        value: 'US-WY',
        label: 'WY',
    },
];
export const provinces = [
    {
        value: 'CA-AB',
        label: 'AB',
    },
    {
        value: 'CA-BC',
        label: 'BC',
    },
    {
        value: 'CA-MB',
        label: 'MB',
    },
    {
        value: 'CA-NB',
        label: 'NB',
    },
    {
        value: 'CA-NL',
        label: 'NL',
    },
    {
        value: 'CA-NS',
        label: 'NS',
    },
    {
        value: 'CA-ON',
        label: 'ON',
    },
    {
        value: 'CA-PE',
        label: 'PE',
    },
    {
        value: 'CA-QC',
        label: 'QC',
    },
    {
        value: 'CA-SK',
        label: 'SK',
    },
];

export const selectCompatibleStates = states.map(stateObj => ({
    value: stateObj.label,
    label: stateObj.label,
}));

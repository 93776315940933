
    import { useI18next } from '@composables/i18next';
    import axios from 'axios';
    import EmailForm from '../components/form/EmailForm.vue';
    import openModal, { ErrorModal } from '../modals';
    import BaseModal from './BaseModal';

    export default {
        name: 'ForgotPasswordModal',

        components: {
            EmailForm,
        },

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['forgot_password', 'buttons', 'forgot_password_confirm']);
            return { t, vT };
        },

        data () {
            return {
                pageName: 'forgot-password',
                loading: false,
                exists:  false,
                success: false,
            };
        },
        methods: {
            async sendPasswordResetRequest (data) {
                try {
                    this.loading = true;
                    if (!data?.email || data.email?.length === 0) {
                        return;
                    }
                    await axios.post('api/profiles/password/forgot', { email: data.email });
                    this.loading = false;
                    this.success = true;
                    this.exists = false;
                }
                catch (err) {
                    console.error(err);
                    const { response } = err;

                    if (response?.status === 404) {
                        this.exists = true;
                    }
                    else if (response.data?.errors) {
                        console.log(response.data?.errors);
                    }
                    else {
                        this.closeModal();
                        openModal(ErrorModal);
                    }
                }
            },
        },
    };

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@public/img/svgs/purelife/tagline.svg'
import _imports_1 from '@public/img/svgs/purelife/white.svg'
import _imports_2 from '@public/img/svgs/purelife/full.svg'


const _hoisted_1 = {
  key: 0,
  class: "purelife-logo",
  src: _imports_0,
  alt: "Pure Life Logo"
}
const _hoisted_2 = {
  key: 1,
  class: "purelife-logo",
  src: _imports_1,
  alt: "Pure Life Logo"
}
const _hoisted_3 = {
  key: 2,
  class: "purelife-logo",
  src: _imports_2,
  alt: "Pure Life Logo"
}


export default {
  __name: 'PureLifeLogo',
  props: {
        version: {
            type: [String, undefined],
            default: undefined,
        },
    },
  setup(__props) {


    


return (_ctx, _cache) => {
  return (__props.version === 'tagline')
    ? (_openBlock(), _createElementBlock("img", _hoisted_1))
    : (__props.version === 'white')
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : (_openBlock(), _createElementBlock("img", _hoisted_3))
}
}

}
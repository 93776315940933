import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "the-drop",
  xmlns: "http://www.w3.org/2000/svg",
  width: "257",
  height: "357",
  viewBox: "0 0 257 357",
  fill: "none"
}
const _hoisted_2 = ["fill"]


export default {
  __name: 'TheDrop',
  props: {
        color: {
            type: String,
            required: false,
            default: '#37C6F4',
        },
    },
  setup(__props) {

    const props = __props;

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M0.139771 224.29C0.139771 297.584 57.4473 357 128.14 357C198.832 357 256.14 297.584 256.14 224.29C256.14 104.391 104.867 0 104.867 0C86.5244 89.8333 0.139771 123.752 0.139771 224.29Z",
      fill: props.color
    }, null, 8, _hoisted_2)
  ]))
}
}

}
const
    apiBase = 'https://lens.veryfi.com/rest/';


async function validatePartner (clientId) {
    const validateUrl = `${apiBase}validate_partner`;

    try {
        const requestOptions = {
                method: 'POST',
                headers: { 'CLIENT-ID': clientId },
            },
            response = await fetch(validateUrl, requestOptions),
            data = await response.json();

        return { session: data.session, clientId };
    }
    catch (error) {
        console.error('Error validating partner:', error);
        return null;
    }
}

async function processImage (image, clientId, username, apiKey, deviceData) {
    const processUrl = `${apiBase}process`;

    try {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                image,
                username,
                api_key: apiKey,
                client_id: clientId,
                device_data: deviceData,
            }),
        };
        const
            response = await fetch(processUrl, requestOptions),
            { status, statusText, ok } = response,
            json = await response.json();

        console.log('DEBUG', { status, statusText, ok, json, username, apiKey });

        return { status, statusText, ok, json };
    }
    catch (error) {
        console.error('Error processing the image:', error);
        throw error; // Re-throw the error for further handling if needed
    }
}

module.exports = {
    validatePartner,
    processImage,
};

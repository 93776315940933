import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { unref as _unref, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "name", "placeholder"]
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "error-message aca-error-message",
  role: "alert",
  "aria-live": "assertive"
}

import { ref } from 'vue';
    import { useAnimation } from '@composables/animation';

    
export default /*@__PURE__*/Object.assign({ inheritAttrs: false }, {
  __name: 'BaseInput',
  props: /*@__PURE__*/_mergeModels({
        fieldClasses: {
            type: String,
            default: 'base-input field',
        },
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        error: {
            type: String,
            required: true,
            default: '',
        },
    }, {
    "modelValue": { type: String, required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

    
    

    const touched = ref(false);

    const { animated } = useAnimation();

    const model = _useModel(__props, "modelValue");

    const updateTouched = () => {
        touched.value = true;
    };

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ error: __props.isError && touched.value, animated: _unref(animated), valued: Boolean(model.value) }, __props.fieldClasses])
  }, [
    _withDirectives(_createElementVNode("input", _mergeProps(_ctx.$attrs, {
      id: __props.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
      name: __props.name,
      placeholder: _unref(animated) ? '' : __props.label,
      onInput: updateTouched
    }), null, 16, _hoisted_1), [
      [_vModelDynamic, model.value]
    ]),
    _createElementVNode("label", { for: __props.name }, _toDisplayString(__props.label), 9, _hoisted_2),
    (__props.isError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(__props.error), 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})